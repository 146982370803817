function App() {
  return (
    <div
      style={{
        height: "100vh",
        maxWidth: "100vw",
        overflow: "hidden",
      }}
    >
      <iframe
        src="./imageleft.html"
        title="Embedded HTML File"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}

export default App;
